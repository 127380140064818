import React from 'react'
import 'twin.macro'

export default function HeroDetail({data,page}){
    let finalData= data?.schema_json;  
    let content= {
        heading:"",
        sub_heading:"",
        description:"",
        img_src:"",
       ...finalData
  } 
    return (
        <div tw="relative bg-white overflow-hidden w-full">
            <div tw="hidden lg:block lg:absolute lg:inset-0" aria-hidden="true">
                <svg tw="absolute top-0 left-1/2 transform translate-x-64 -translate-y-8" width="640" height="784" fill="none" viewBox="0 0 640 784">
                <defs>
                    <pattern id="9ebea6f4-a1f5-4d96-8c4e-4c2abf658047" x="118" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                    <rect x="0" y="0" width="4" height="4" tw="text-gray-200" fill="currentColor" />
                    </pattern>
                </defs>
                <rect y="72" width="640" height="640" tw="text-gray-50" fill="currentColor" />
                <rect x="118" width="404" height="784" fill="url(#9ebea6f4-a1f5-4d96-8c4e-4c2abf658047)" />
                </svg>
            </div>
            <div tw="relative pt-6 pb-8 sm:pb-12 lg:pb-16">
                <main tw="mt-8 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 lg:mt-16">
                <div tw="lg:grid lg:grid-cols-12 lg:gap-8">
                    <div tw="sm:text-center md:max-w-xl md:mx-auto lg:col-span-6 lg:text-left ">
                    <div tw="px-4">
                        <div tw="block text-sm font-semibold uppercase tracking-wide text-gray-500 sm:text-base lg:text-sm xl:text-base">{content.sub_heading}</div>
                        <div tw="mt-1 block text-4xl tracking-tight font-extrabold sm:text-4xl xl:text-5xl text-gray-900"
                            dangerouslySetInnerHTML={{ __html: content.heading }}
                        >
                        </div>
                    </div>
                    <div tw="mt-3 text-base text-gray-500 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl" dangerouslySetInnerHTML={{ __html: content.description }}></div>
                    </div>
                    <div tw="mt-12 relative sm:max-w-lg sm:mx-auto lg:mt-0 lg:max-w-none lg:mx-0 lg:col-span-6 lg:flex lg:items-center">
                    <svg tw="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-8 scale-75 origin-top sm:scale-100 lg:hidden" width="640" height="784" fill="none" viewBox="0 0 640 784" aria-hidden="true">
                        <defs>
                        <pattern id="4f4f415c-a0e9-44c2-9601-6ded5a34a13e" x="118" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                            <rect x="0" y="0" width="4" height="4" tw="text-gray-200" fill="currentColor" />
                        </pattern>
                        </defs>
                        <rect y="72" width="640" height="640" tw="text-gray-50" fill="currentColor" />
                        <rect x="118" width="404" height="784" fill="url(#4f4f415c-a0e9-44c2-9601-6ded5a34a13e)" />
                    </svg>
                    <div tw="relative mx-auto w-full rounded-lg shadow-lg lg:max-w-7xl">
                        <img tw="w-full rounded-lg" src={content.img_src} alt="" />
                    </div>
                    </div>
                </div>
                </main>
            </div>
            </div>
    )
}
